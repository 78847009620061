import { toFormData } from '~/utils';

export type RegisterUserVariables = {
  email: string;
  password1: string;
  password2: string;
  first_name: string;
  last_name: string;
  link_uuid?: string;
  sex?: 0 | 1 | 2;
  date_of_birth?: string;
  phone_number?: string;
  address_first_line?: string;
  zip_code?: string;
  city?: string;
  administrative_area?: string;
  country?: string;
};

type Identifier = {
  category: string;
  value: string;
};

type RegisterUserResult = {
  success: {
    access: string;
    refresh: 'string';
    user: {
      pk: string;
      username: string;
      email: string;
      first_name: string;
      last_name: string;
      identifiers: Array<Identifier>;
      readable_id: number;
    }
  };
};

export type RegisterUserVariableField = keyof RegisterUserVariables;

type RegisterUserErrors = {
  errors: Partial<{
    [K in RegisterUserVariableField]: Array<string>;
  } & {
    non_field_errors: Array<string>;
    detail: string;
  }>;
};

export type RegisterUserMutationRes = RegisterUserResult | RegisterUserErrors;

export const registerUser = async (variables: RegisterUserVariables): Promise<RegisterUserMutationRes> => {
  const fetchCreateAnswer = await fetch(process.env.API_URL + '/api/registration/', {
    method: 'POST',
    body: toFormData({
      ...variables,
    }),
  });

  const res = await fetchCreateAnswer.json();

  if (![200, 201].includes(fetchCreateAnswer.status)) {
    return {
      errors: res,
    };
  }

  return {
    success: res,
  };
};
