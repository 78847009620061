import styled, { css } from 'styled-components';

import { Fonts } from '~/styles';

export type ButtonSize = 'large' | 'medium' | 'small';
export type ButtonVariant = 'primary' | 'secondary' | 'naked';

interface ContainerProps {
  $size: ButtonSize;
  $variant: ButtonVariant;
  $loading: boolean;
}

const sizeLargeCss = css`
  padding: 20px 28px;
  height: 60px;
  border-radius: 100px;
  font-size: 16px;
`;

const sizeMediumCss = css`
  padding: 16px 24px;
  height: 52px;
  border-radius: 100px;
  font-size: 16px;
`;

const sizeSmallCss = css`
  padding: 12px 24px;
  height: 44px;
  border-radius: 25px;
  font-size: 16px;
`;

const variantPrimaryCss = css`
  background-color: ${p => p.theme.colors.primary.blue};
  &:hover {
    background-color: ${p => p.theme.colors.primary.white};
    color: ${p => p.theme.colors.primary.blue};
    border-color: ${p => p.theme.colors.primary.blue};
  }
  &:focus {
    color: ${p => p.theme.colors.primary.white};
    background-color: ${p => p.theme.colors.primary.blue};
    outline: 2px solid ${p => p.theme.colors.primary.blue};
    border-color: ${p => p.theme.colors.primary.white};
  }
`;

const variantSecondaryCss = css`
  background-color: ${p => p.theme.colors.primary.pink};
  &:hover {
    background-color: ${p => p.theme.colors.primary.white};
    color: ${p => p.theme.colors.primary.pink};
    border-color: ${p => p.theme.colors.primary.pink};
  }
  &:focus {
    color: ${p => p.theme.colors.primary.white};
    background-color: ${p => p.theme.colors.primary.pink};
    outline: 2px solid ${p => p.theme.colors.primary.pink};
    border-color: ${p => p.theme.colors.primary.white};
  }
`;

const variantNakedCss = css`
  background-color: transparent;
  color: ${p => p.theme.colors.primary.blue};
  &:hover {
    border-color: ${p => p.theme.colors.primary.blue};
  }
`;

export const Container = styled.button<ContainerProps>`
  color: white;

  font-family: ${Fonts.Fractul};
  font-weight: 700;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px solid transparent;

  span.label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  ${p => p.$size === 'large' && sizeLargeCss};
  ${p => p.$size === 'medium' && sizeMediumCss};
  ${p => p.$size === 'small' && sizeSmallCss};

  ${p => p.$variant === 'primary' && variantPrimaryCss};
  ${p => p.$variant === 'secondary' && variantSecondaryCss};
  ${p => p.$variant === 'naked' && variantNakedCss};

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  ${p => p.$loading && css`
    pointer-events: none;
    justify-content: center;

    span.label {
      opacity: 0;
    }

    svg.spinner {
      position: absolute;
    }
  `};
`;
