import styled from 'styled-components';

export const Container = styled.div`
  color: ${p => p.theme.colors.semantic.error};
  border-radius: 16px;
  border: 2px solid ${p => p.theme.colors.semantic.error};

  font-size: 15px;
  font-weight: 500;

  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  h2 {
    padding: 0;
    margin: 0;
    color: inherit;
  }

  ul {
    padding: 0;
  }
`;
