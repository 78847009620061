import { FC } from 'react';
import { useLoaderData } from 'react-router-dom';

import { DreemSecure } from '~/svg';

import { Container } from './ActivatedPage.styles';
import { LinkExpiredPage } from '..';

export const ActivatedPage: FC = () => {
  const tokenIsValid = useLoaderData();

  if (!tokenIsValid) {
    return <LinkExpiredPage />;
  }

  return (
    <Container>
      <DreemSecure />
      <h1>
        Your account is<br />
        <span>successfully activated!</span>
      </h1>
      <p>You can now open your<br />Dreem Health app.</p>
    </Container>
  );
};
