import { FC } from 'react';

import { DreemStars } from '~/svg';

import { Container } from './AccountCreatedPage.styles';

export const AccountCreatedPage: FC = () => {
  return (
    <Container>
      <DreemStars />
      <h1>Your account is created! </h1>
      <p>You can now access the Dreem Health app </p>
    </Container>
  );
};
