
import { FC } from 'react';
import { QueryClientProvider } from 'react-query';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { client } from '~/api';
import { router } from '~/router';
import { GlobalStyles, defaultTheme } from '~/styles';

const App: FC = () => (
  <QueryClientProvider client={client}>
    <ThemeProvider theme={defaultTheme}>
      <RouterProvider router={router} />
      <GlobalStyles />
    </ThemeProvider>
  </QueryClientProvider>
);

export default App;
