import styled, { css } from 'styled-components';

import { BreakPoint, minWidth } from '~/styles';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Container = styled.div<{ $large?: boolean; }>`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  ${p => !p.$large && css`
    justify-content: center;
  `};

  h2 {
    color: ${p => p.theme.colors.blues.b70};
    font-size: 18px;
  }

  ${minWidth(BreakPoint.TabletLandscape)} {
    
  }

  ${Wrapper} {
    ${minWidth(BreakPoint.TabletLandscape)} {
      width: ${p => p.$large ? 810 : 508}px;
      padding: 64px 40px;
      border-radius: 24px;
      background-color: #fff;
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 32px;
  left: 0;
  width: 100%;

  svg {
    width: 120px;
    height: auto;
  }

  ${minWidth(BreakPoint.TabletLandscape)} {
    position: static;
  }
`;

export const Content = styled.div`
  margin: 32px auto 0;
  padding: 0 0 32px;
  width: 100%;
  max-width: 650px;
`;
