import { ComponentPropsWithoutRef, FC } from 'react';

import { Spinner } from '~/components';

import { ButtonSize, ButtonVariant, Container } from './Button.styles';

type Props = ComponentPropsWithoutRef<'button'> & {
  size?: ButtonSize;
  variant?: ButtonVariant;
  loading?: boolean;
};

export const Button: FC<Props> = ({
  children,
  size = 'large',
  variant = 'primary',
  loading = false,
  ...buttonProps
}) => {
  return (
    <Container
      $size={size}
      $variant={variant}
      $loading={loading}
      {...buttonProps}
    >
      <span className="label">{children}</span>
      {loading && <Spinner />}
    </Container>
  );
};
