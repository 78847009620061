import { FC } from 'react';

import { DreemMail } from '~/svg';

import { Container } from './YouHaveGoMailPage.styles';

export const YouHaveGoMailPage: FC = () => {
  return (
    <Container>
      <DreemMail />
      <h1>You’ve got mail!</h1>
      <p>
        Check your inbox and click on the link you<br />
        received to confirm your password.
      </p>
    </Container>
  );
};
