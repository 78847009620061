import { toFormData } from '~/utils';

export type SetNewPasswordMutationVariables = {
  new_password1: string;
  new_password2: string;
  uid: string;
  token: string;
};

type SetNewPasswordResult = {
  success: {
    detail: string;
  };
};

export type SetNewPasswordVariableField = keyof SetNewPasswordMutationVariables;

type SetNewPasswordErrors = {
  errors: Partial<{
    [K in SetNewPasswordVariableField]: Array<string>;
  }>;
};

type SetNewPasswordMutationRes = SetNewPasswordResult | SetNewPasswordErrors;

export const setNewPassword = async (variables: SetNewPasswordMutationVariables): Promise<SetNewPasswordMutationRes> => {
  const fetchResetPassword = await fetch(process.env.API_URL + '/auth/password/reset/confirm/', {
    method: 'POST',
    body: toFormData({
      ...variables,
    }),
  });

  const res = await fetchResetPassword.json();

  if (![200, 201].includes(fetchResetPassword.status)) {
    return {
      errors: res,
    };
  }

  return {
    success: res,
  };
};
