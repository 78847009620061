/* eslint-disable @typescript-eslint/no-explicit-any */
export const toFormData = (obj: Record<string, any>) => {
  const formData = new FormData();
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    const strValue = typeof value === 'object' ? JSON.stringify(value) : String(value);
    formData.append(key, strValue);
  });
  return formData;
};
