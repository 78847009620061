import styled from 'styled-components';

import { BreakPoint, Fonts, minWidth } from '~/styles';

export const H2Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;

export const Fields = styled.div<{ cols?: number }>`
  display: flex;
  flex-direction: column;

  ${minWidth(BreakPoint.TabletLandscape)} {
    display: grid;
    grid-template-columns: repeat(${p => p.cols ?? 2}, 1fr);
    gap: 0 20px;

    ${H2Container}, .full {
      grid-column: span ${p => p.cols ?? 2};
    }
  }

  h2 {
    margin: 0;
    border-radius: 100px;
    font-family: ${Fonts.Fractul};
    font-size: 16px;
    color: ${p => p.theme.colors.primary.blue};
    background-color: #FFF3E6;
    padding: 4px 12px;
    align-self: center;
  }

  padding-top: 12px;
`;
