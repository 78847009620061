import { ComponentPropsWithoutRef, FC } from 'react';

import { Svg } from '~/components';

type Props = ComponentPropsWithoutRef<'svg'>;

export const CheckIcon: FC<Props> = (svgProps) => {
  return (
    <Svg {...svgProps} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M19.5735 5.18085C20.0259 5.49757 20.136 6.1211 19.8192 6.57355L11.4192 18.5735C11.2463 18.8207 10.971 18.9765 10.67 18.9976C10.3691 19.0188 10.0748 18.9029 9.86895 18.6824L4.26895 12.6824C3.89211 12.2787 3.91393 11.6459 4.31769 11.269C4.72144 10.8922 5.35423 10.914 5.73106 11.3178L10.4887 16.4153L18.1808 5.42662C18.4975 4.97417 19.121 4.86414 19.5735 5.18085Z" fill="#FF8795" />
    </Svg>
  );
};
