import { createBrowserRouter } from 'react-router-dom';

import { MainLayout } from '~/layouts';
import { AccountCreatedPage, ActivatedPage, IndexPage, RegistrationPage, ResetPasswordConfirmationPage, ResetPasswordPage } from '~/pages';

import { activateLoader } from './loaders';
import { YouHaveGoMailPage } from './pages/YouHaveGoMailPage/YouHaveGoMailPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
      {
        path: 'password-reset',
        element: <ResetPasswordPage />,
      },
      {
        path: 'password-reset/confirm/:uid/:token',
        element: <ResetPasswordConfirmationPage />,
      },
      {
        path: 'activate',
        loader: activateLoader,
        element: <ActivatedPage />,
      },
      {
        path: 'you-have-got-mail',
        element: <YouHaveGoMailPage />,
      },
      {
        path: 'account-created',
        element: <AccountCreatedPage />,
      },
    ],
  },
  {
    path: '/registration',
    element: <MainLayout largeWrapper />,
    children: [
      {
        index: true,
        element: <RegistrationPage />,
      },
    ],
  },
]);
