import { FC } from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SvgSpinner = styled.svg`
  animation: ${rotate} 800ms linear infinite;
  width: 24px;
  height: 24px;
`;

interface Props {
  onLight?: boolean;
}

export const Spinner: FC<Props> = ({ onLight }) => {
  if (onLight) {
    return (
      <SvgSpinner width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4509_6782)">
          <path d="M10 1.35897C10 0.608431 9.38879 -0.00950073 8.64517 0.0921806C6.74775 0.351631 4.95432 1.1523 3.48674 2.41202C1.67357 3.96838 0.477634 6.12257 0.115503 8.48451C-0.24663 10.8464 0.248978 13.26 1.51258 15.2881C2.77619 17.3162 4.72425 18.8248 7.00402 19.5407C9.2838 20.2566 11.7446 20.1324 13.9407 19.1908C16.1369 18.2491 17.9233 16.5522 18.9764 14.4073C20.0295 12.2623 20.2798 9.81115 19.6818 7.49764C19.1979 5.62508 18.1841 3.9429 16.7756 2.64532C16.2236 2.13679 15.3689 2.29438 14.9398 2.91018C14.5108 3.52599 14.6738 4.36546 15.1993 4.90134C16.0872 5.80684 16.7291 6.9347 17.0504 8.17776C17.4858 9.86248 17.3036 11.6474 16.5367 13.2094C15.7698 14.7714 14.4689 16.0071 12.8697 16.6928C11.2704 17.3785 9.47846 17.4689 7.81831 16.9476C6.15817 16.4262 4.73957 15.3277 3.81941 13.8508C2.89924 12.3739 2.53834 10.6164 2.80205 8.89641C3.06575 7.17643 3.93664 5.60774 5.257 4.47438C6.23123 3.63814 7.40265 3.07969 8.64886 2.84437C9.38636 2.7051 10 2.10951 10 1.35897Z" fill="url(#paint0_linear_4509_6782)" />
          <path d="M10 1.35754C10 0.607791 9.38947 -0.00946522 8.64662 0.0920052C7.78063 0.210296 6.93193 0.441878 6.12267 0.782281C4.89521 1.2986 3.78323 2.05487 2.85198 3.00673C1.92073 3.95859 1.18897 5.08685 0.699638 6.32532C0.210304 7.56379 -0.0267467 8.88752 0.00239563 10.2188C0.0315361 11.5502 0.326283 12.8622 0.869337 14.0781C1.41239 15.294 2.19282 16.3891 3.16483 17.2993C4.13684 18.2095 5.28084 18.9164 6.52973 19.3785C7.3531 19.6832 8.21112 19.8774 9.08145 19.9577C9.82803 20.0266 10.411 19.3832 10.3782 18.6342C10.3454 17.8852 9.70697 17.3175 8.96483 17.211C8.45646 17.138 7.95598 17.0113 7.47193 16.8322C6.56213 16.4956 5.72873 15.9806 5.02063 15.3175C4.31253 14.6544 3.744 13.8566 3.34838 12.9709C2.95277 12.0851 2.73805 11.1293 2.71682 10.1594C2.69559 9.18957 2.86828 8.22524 3.22476 7.32302C3.58124 6.42081 4.11432 5.59888 4.79272 4.90546C5.47113 4.21204 6.2812 3.6611 7.1754 3.28496C7.65114 3.08485 8.14561 2.93636 8.6503 2.8412C9.38706 2.70229 10 2.10729 10 1.35754Z" fill="#FFC3CA" />
          <path d="M10 1.36307C10 0.610266 9.38696 -0.00954099 8.64114 0.0927549C7.79512 0.208795 6.96556 0.432983 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C2.00035 3.85752 1.26375 4.95991 0.761204 6.17317C0.432981 6.96556 0.208794 7.79512 0.0927544 8.64114C-0.00954056 9.38696 0.610266 10 1.36307 10C2.11587 10 2.71323 9.38451 2.85348 8.64489C2.94608 8.15656 3.0887 7.67782 3.27983 7.21641C3.64537 6.33391 4.18116 5.53204 4.8566 4.8566C5.53204 4.18116 6.33391 3.64537 7.21641 3.27983C7.67782 3.0887 8.15656 2.94607 8.64489 2.85348C9.38451 2.71323 10 2.11587 10 1.36307Z" fill="#FF8795" />
        </g>
        <defs>
          <linearGradient id="paint0_linear_4509_6782" x1="17.0833" y1="2.08333" x2="17.3449" y2="19.8675" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF3E6" stopOpacity="0" />
            <stop offset="1" stopColor="#FFE188" />
          </linearGradient>
          <clipPath id="clip0_4509_6782">
            <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 1 20 0)" />
          </clipPath>
        </defs>
      </SvgSpinner>
    );
  }
  return (
    <SvgSpinner width="20" height="20" viewBox="0 0 20 20" fill="none" className="spinner">
      <g clipPath="url(#clip0_1601_1002)">
        <path d="M10 1.35897C10 0.608431 9.38879 -0.00950073 8.64517 0.0921806C6.74775 0.351631 4.95432 1.1523 3.48674 2.41202C1.67357 3.96838 0.477634 6.12257 0.115503 8.48451C-0.24663 10.8464 0.248978 13.26 1.51258 15.2881C2.77619 17.3162 4.72425 18.8248 7.00402 19.5407C9.2838 20.2566 11.7446 20.1324 13.9407 19.1908C16.1369 18.2491 17.9233 16.5522 18.9764 14.4073C20.0295 12.2623 20.2798 9.81115 19.6818 7.49764C19.1979 5.62508 18.1841 3.9429 16.7756 2.64532C16.2236 2.13679 15.3689 2.29438 14.9398 2.91018C14.5108 3.52599 14.6738 4.36546 15.1993 4.90134C16.0872 5.80684 16.7291 6.9347 17.0504 8.17776C17.4858 9.86248 17.3036 11.6474 16.5367 13.2094C15.7698 14.7714 14.4689 16.0071 12.8697 16.6928C11.2704 17.3785 9.47846 17.4689 7.81831 16.9476C6.15817 16.4262 4.73957 15.3277 3.81941 13.8508C2.89924 12.3739 2.53834 10.6164 2.80205 8.89641C3.06575 7.17643 3.93664 5.60774 5.257 4.47438C6.23123 3.63814 7.40265 3.07969 8.64886 2.84437C9.38636 2.7051 10 2.10951 10 1.35897Z" fill="url(#paint0_linear_1601_1002)" />
        <path d="M10 1.35754C10 0.607791 9.38947 -0.00946522 8.64662 0.0920052C7.78063 0.210296 6.93193 0.441878 6.12267 0.782281C4.89521 1.2986 3.78323 2.05487 2.85198 3.00673C1.92073 3.95859 1.18897 5.08685 0.699638 6.32532C0.210304 7.56379 -0.0267467 8.88752 0.00239563 10.2188C0.0315361 11.5502 0.326283 12.8622 0.869337 14.0781C1.41239 15.294 2.19282 16.3891 3.16483 17.2993C4.13684 18.2095 5.28084 18.9164 6.52973 19.3785C7.3531 19.6832 8.21112 19.8774 9.08145 19.9577C9.82803 20.0266 10.411 19.3832 10.3782 18.6342C10.3454 17.8852 9.70697 17.3175 8.96483 17.211C8.45646 17.138 7.95598 17.0113 7.47193 16.8322C6.56213 16.4956 5.72873 15.9806 5.02063 15.3175C4.31253 14.6544 3.744 13.8566 3.34838 12.9709C2.95277 12.0851 2.73805 11.1293 2.71682 10.1594C2.69559 9.18957 2.86828 8.22524 3.22476 7.32302C3.58124 6.42081 4.11432 5.59888 4.79272 4.90546C5.47113 4.21204 6.2812 3.6611 7.1754 3.28496C7.65114 3.08485 8.14561 2.93636 8.6503 2.8412C9.38706 2.70229 10 2.10729 10 1.35754Z" fill="#FFE188" />
        <path d="M10 1.36307C10 0.610266 9.38696 -0.00954099 8.64114 0.0927549C7.79512 0.208795 6.96556 0.432983 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C2.00035 3.85752 1.26375 4.95991 0.761204 6.17317C0.432981 6.96556 0.208794 7.79512 0.0927544 8.64114C-0.00954056 9.38696 0.610266 10 1.36307 10C2.11587 10 2.71323 9.38451 2.85348 8.64489C2.94608 8.15656 3.0887 7.67782 3.27983 7.21641C3.64537 6.33391 4.18116 5.53204 4.8566 4.8566C5.53204 4.18116 6.33391 3.64537 7.21641 3.27983C7.67782 3.0887 8.15656 2.94607 8.64489 2.85348C9.38451 2.71323 10 2.11587 10 1.36307Z" fill="#FFE188" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_1601_1002" x1="10" y1="0" x2="10" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_1601_1002">
          <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 1 20 0)" />
        </clipPath>
      </defs>
    </SvgSpinner>
  );
};
