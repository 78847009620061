import { ComponentPropsWithRef, FC } from 'react';

import { Svg } from '~/components';

type Props = ComponentPropsWithRef<'svg'>;

export const CloseEyeIcon: FC<Props> = ({ ...svgProps }) => {
  return (
    <Svg {...svgProps} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4678_164895)">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.56244 3.80679C9.21298 3.76135 8.85818 3.73801 8.50008 3.73801C5.4557 3.73801 2.64891 5.42501 1.35013 8.03556L1.16675 8.40467L1.35013 8.7736C1.69985 9.47658 2.15891 10.1126 2.7025 10.6667L6.02856 7.34066C6.31426 6.62181 6.88044 6.05357 7.584 5.78522L9.56244 3.80679ZM9.41741 11.0229C10.12 10.7545 10.6855 10.1871 10.9711 9.46915L14.2977 6.14262C14.8413 6.69676 15.3003 7.33276 15.65 8.03575L15.8334 8.40467L15.65 8.77379C14.3512 11.3843 11.5445 13.0713 8.50008 13.0713C8.14199 13.0713 7.78718 13.048 7.43773 13.0026L9.41741 11.0229Z" fill="#54477B" />
        <path fillRule="evenodd" clipRule="evenodd" d="M16.1096 1.73794L1.83343 16.0141L0.890625 15.0713L15.1668 0.795135L16.1096 1.73794Z" fill="#54477B" />
      </g>
      <defs>
        <clipPath id="clip0_4678_164895">
          <rect width="16" height="16" fill="white" transform="translate(0.5 0.404663)" />
        </clipPath>
      </defs>
    </Svg>
  );
};
