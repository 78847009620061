import styled from 'styled-components';

import { Fonts } from '~/styles';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 30px;

  svg {
    position: absolute;
    top: 24px;
    right: 12px;
    z-index: 1;
  }

  label {
    user-select: none;
    pointer-events: none;
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 20;

    display: flex;
    gap: 4px;

    color: ${p => p.theme.colors.blues.b50};
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: ${Fonts.Fractul};

    span {
      color: ${p => p.theme.colors.primary.pink};
      font-weight: 500;
    }
  }

  select, input {
    height: 64px;
    padding: 24px 12px 12px;
  }
`;

export const Helper = styled.div`
  position: absolute;

  top: 72px;
  left: 9px;

  white-space: nowrap;
  color: ${p => p.theme.colors.semantic.error};
  font-size: 12px;
  font-family: ${Fonts.Fractul};
  font-weight: 500;
  line-height: 14.4px;
`;
