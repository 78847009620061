import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 span {
    color: ${p => p.theme.colors.primary.pink};
  }
  p {
    margin: 0;
    color: ${p => p.theme.colors.blues.b70};
    text-align: center;
  }
`;
