import { useEffect, useState } from 'react';

// @todo: better typing
type Result = any;
type SetError = any;

export const useFormErrors = (result: Result, setError: SetError) => {
  const [nonFieldErrors, setNonFieldErrors] = useState<Array<string>>([]);

  useEffect(() => {
    if (!result) return;

    if ('errors' in result) {
      if (result.errors.detail) {
        setNonFieldErrors([result.errors.detail]);
      }

      if (result.errors.non_field_errors) {
        setNonFieldErrors(result.errors.non_field_errors);
      }

      if (result.errors.token) {
        setNonFieldErrors([`Token: ${result.errors.token[0]}`]);
      }

      if (result.errors.uid) {
        setNonFieldErrors(result.errors.uid);
      }

      Object.keys(result.errors)
        .filter(inputKey => inputKey !== 'detail' && inputKey !== 'non_field_errors')
        .forEach((inputKey) => {
          const k = inputKey;

          const errs = result.errors[k];

          if (!errs || !errs.length) return;

          setError(k, {
            message: errs[0],
          });
        });
    }
  }, [result, setError]);

  return {
    nonFieldErrors,
  };
};
