import { ComponentPropsWithoutRef, ForwardedRef, forwardRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Input } from '~/components';
import { CloseEyeIcon, EyeIcon } from '~/icons';
import { getErrorMessage } from '~/utils';

import { Container, Helper } from './Field.styles';

type Props = ComponentPropsWithoutRef<'input'> & { label: string; isRequired?: boolean };

export const Field = forwardRef(({ className, label, isRequired, ...inputProps }: Props, ref: ForwardedRef<HTMLInputElement>) => {
  const { formState: { errors } } = useFormContext();

  const isPasswordField = inputProps.type === 'password';
  const [showPassword, setShowPassword] = useState(false);

  if (!inputProps.name) return null;
  const fieldError = errors[inputProps.name];

  return (
    <Container className={className}>
      <label>
        {label}{isRequired && '*'}
      </label>
      {isPasswordField && (
        <>
          {showPassword ? (
            <CloseEyeIcon className="eye-icon" onClick={() => setShowPassword(false)} />
          ) : (
            <EyeIcon className="eye-icon" onClick={() => setShowPassword(true)} />
          )}
        </>
      )}

      <Input
        ref={ref}
        {...inputProps}
        $error={!!fieldError}
        type={isPasswordField && showPassword ? 'text' : inputProps.type}
      />
      <Helper>{getErrorMessage(fieldError)}</Helper>
    </Container>
  );
});
