import { FC } from 'react';

export const DreemLogo: FC = () => {
  return (
    <svg width="125" height="15" viewBox="0 0 125 15" fill="none">
      <path d="M43.2021 12.8849C43.9684 12.8849 44.4695 10.9967 44.833 9.48996C45.0982 8.38889 45.3733 7.17191 45.8007 6.28332C46.4049 5.01323 47.181 4.35645 48.3893 4.35645C49.2686 4.35645 50.2363 4.68966 50.9633 6.26884C51.3759 7.15742 51.6166 8.28264 51.8573 9.36923C52.2355 11.0836 52.4369 12.9622 53.2769 12.9622C54.1169 12.9622 54.3183 11.0981 54.6965 9.36923C54.9372 8.28264 55.173 7.15742 55.5905 6.26884C56.3175 4.68966 57.2999 4.35645 58.1645 4.35645C59.3827 4.35645 60.149 4.99874 60.7532 6.28332C61.1805 7.1864 61.4556 8.38889 61.7208 9.48996C62.0843 11.0064 62.5854 12.8849 63.3517 12.8849V14.947C62.2071 14.947 61.3033 14.3917 60.6746 13.0926C60.2374 12.1654 59.9476 11.0788 59.6823 9.9584C59.2795 8.27781 59.0143 6.32196 58.1645 6.32196C57.3245 6.32196 57.1231 8.0122 56.7449 9.73142C56.5042 10.818 56.2684 12.1509 55.8509 13.0395C55.1239 14.6186 54.2446 14.9519 53.2769 14.9519C52.3092 14.9519 51.43 14.6186 50.703 13.0395C50.2903 12.1509 50.0496 10.818 49.809 9.73142C49.4307 8.01703 49.2293 6.32196 48.3893 6.32196C47.5494 6.32196 47.2694 8.2923 46.8715 9.9584C46.5964 11.0836 46.3213 12.1799 45.8793 13.0926C45.2505 14.4013 44.3467 14.947 43.2021 14.947" fill="#2A195A" />
      <path d="M65.3418 10.3443V0H67.4147V5.77098C68.2105 4.92586 69.4483 4.38981 70.8286 4.38981C73.8447 4.38981 75.5443 6.36981 75.5443 9.46055V14.9804H73.4714V9.6682C73.4714 7.87654 72.4644 6.33118 70.5143 6.33118C68.6919 6.33118 67.4147 7.77513 67.4147 9.73098V10.354H65.3418V10.3443Z" fill="#2A195A" />
      <path d="M87.2737 9.39827C87.1754 6.34617 84.8864 4.27441 82.111 4.27441C79.1981 4.27441 76.7617 6.42344 76.7617 9.64456C76.7617 12.8657 79.2129 14.9809 82.0226 14.9809C84.0464 14.9809 85.7018 14.1551 86.6547 12.489C86.8168 12.2041 86.9937 11.7598 87.0575 11.5376H84.9846C84.2576 12.8319 83.1377 13.141 82.0324 13.141C80.5146 13.141 79.1195 12.0157 78.9181 10.142H87.2687V9.4031L87.2737 9.39827ZM79.0115 8.44691C79.4143 6.93051 80.8093 6.14817 82.1012 6.14817C83.3931 6.14817 84.6113 6.90154 84.9895 8.44691H79.0115Z" fill="#2A195A" />
      <path d="M88.2744 9.66321C88.2744 6.78014 90.6224 4.38965 93.7416 4.38965C96.8608 4.38965 99.2088 6.65458 99.2088 9.66321V14.9754H97.239V12.9568C96.4629 14.1931 95.1023 14.9754 93.4272 14.9754C90.4505 14.9754 88.2744 12.5849 88.2744 9.65839V9.66321ZM97.1408 9.68253C97.1408 7.82809 95.6721 6.30204 93.7465 6.30204C91.821 6.30204 90.3522 7.82809 90.3522 9.68253C90.3522 11.537 91.8406 13.0389 93.7465 13.0389C95.6524 13.0389 97.1408 11.5128 97.1408 9.68253Z" fill="#2A195A" />
      <path d="M103.631 0V14.9756H101.558V0H103.631Z" fill="#2A195A" />
      <path d="M106.71 11.1072V6.40836H104.677V4.63602H106.71V2.01855H108.783V4.63602H112.723V6.40836H108.783V11.0445C108.783 12.3629 109.579 13.1452 110.817 13.1452C111.49 13.1452 112.222 12.8989 112.723 12.5464V14.4201C112.197 14.7678 111.382 14.9948 110.625 14.9948C108.071 14.9948 106.705 13.406 106.705 11.1024L106.71 11.1072Z" fill="#2A195A" />
      <path d="M114.757 14.9756V0H116.83V5.77098C117.626 4.92586 118.863 4.38981 120.244 4.38981C123.26 4.38981 124.959 6.36981 124.959 9.46055V14.9804H122.886V9.6682C122.886 7.87654 121.879 6.33118 119.934 6.33118C118.112 6.33118 116.835 7.77513 116.835 9.73098V14.9852H114.762L114.757 14.9756Z" fill="#2A195A" />
      <path d="M30.8733 9.39729C30.775 6.34519 28.486 4.27344 25.7106 4.27344C22.7977 4.27344 20.3613 6.42246 20.3613 9.64359C20.3613 12.8647 22.8125 14.9799 25.6222 14.9799C27.646 14.9799 29.3014 14.1541 30.2543 12.488C30.4164 12.2031 30.5933 11.7588 30.6571 11.5367H28.5842C27.8572 12.8309 26.7373 13.14 25.632 13.14C24.1142 13.14 22.7191 12.0148 22.5177 10.141H30.8684V9.40212L30.8733 9.39729ZM22.6111 8.44593C23.0139 6.92954 24.4089 6.14719 25.7008 6.14719C26.9927 6.14719 28.2109 6.90056 28.5891 8.44593H22.6111Z" fill="#2A195A" />
      <path d="M42.7404 9.39729C42.6422 6.34519 40.3532 4.27344 37.5778 4.27344C34.6649 4.27344 32.2285 6.42246 32.2285 9.64359C32.2285 12.8647 34.6797 14.9799 37.4894 14.9799C39.5132 14.9799 41.1686 14.1541 42.1215 12.488C42.2836 12.2031 42.4605 11.7588 42.5243 11.5367H40.4514C39.7244 12.8309 38.6044 13.14 37.4992 13.14C35.9814 13.14 34.5863 12.0148 34.3849 10.141H42.7355V9.40212L42.7404 9.39729ZM34.4783 8.44593C34.8811 6.92954 36.2761 6.14719 37.568 6.14719C38.8599 6.14719 40.0781 6.90056 40.4563 8.44593H34.4783Z" fill="#2A195A" />
      <path d="M8.83691 0V5.72752C7.88395 4.76649 6.64119 4.28356 5.10861 4.28356C3.7283 4.28356 2.53465 4.79064 1.51785 5.7903C0.501036 6.80444 0 8.08903 0 9.62957C0 11.1701 0.501036 12.4595 1.51785 13.4688C2.52483 14.483 3.7283 14.9756 5.10861 14.9756C6.62645 14.9756 7.86922 14.4926 8.83691 13.5413V14.9756H11.0081V0H8.83691ZM7.80536 12.1263C7.12749 12.7686 6.33664 13.0873 5.44263 13.0873C4.48968 13.1018 3.69883 12.7783 3.05534 12.1504C2.41185 11.5226 2.10239 10.6824 2.10239 9.62957C2.10239 8.57679 2.41676 7.76547 3.05534 7.12318C3.69391 6.48088 4.48477 6.16215 5.44263 6.16215C6.34646 6.16215 7.12749 6.48088 7.80536 7.12318C8.48323 7.76547 8.82217 8.60576 8.82217 9.61508C8.82217 10.6244 8.48323 11.4792 7.80536 12.1215V12.1263Z" fill="#2A195A" />
      <path d="M19.3634 4.69423C17.5655 4.69423 16.3228 5.32203 15.6203 6.42311V4.79564H13.459V14.9758H15.6203V11.0351C15.6203 8.29204 16.7992 6.79979 19.3388 6.79979H19.663V4.69906C19.5746 4.68457 19.4763 4.68457 19.3634 4.68457V4.69423Z" fill="#2A195A" />
      <path d="M67.8027 13.5513C67.8027 14.0922 67.5031 14.5606 67.061 14.8118H67.0561C66.84 14.9325 66.5944 15.0001 66.3291 15.0001C65.5137 15.0001 64.8555 14.353 64.8555 13.5513C64.8555 13.2084 64.9734 12.8897 65.1797 12.6434C65.4498 12.315 65.8625 12.1025 66.3291 12.1025C67.1396 12.1025 67.8027 12.7497 67.8027 13.5513Z" fill="#FF8795" />
    </svg>
  );
};
