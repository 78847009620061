import { useMemo } from 'react';

export const usePasswordIsValid = (password: string) => {
  const [has8characters, hasLowercase, hasUppercase, hasNumber, hasSpecialCharacter] = useMemo(() => {
    return [
      /^(?=.{8,})/,
      /[a-z]/,
      /[A-Z]/,
      /[0-9]/,
      /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
    ].map((regex) => regex.test(password));
  }, [password]);

  return {
    has8characters,
    hasLowercase,
    hasUppercase,
    hasNumber,
    hasSpecialCharacter,
    hasAllConditions: (
      has8characters &&
      hasLowercase &&
      hasUppercase &&
      hasNumber &&
      hasSpecialCharacter
    ),
  };
};
