import styled, { css } from 'styled-components';

import { BreakPoint, minWidth } from '~/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;


  h1 {
    text-align: center;
    margin: 40px 0 0;
  }
  p {
    text-align: center;
    margin: 12px 0 40px;
  }

  form {
    width: 100%;
  }
`;

export const PasswordHelper = styled.div`
  border-radius: 20px;
  background: rgba(255, 227, 231, 0.20);
  padding: 12px;
  h3 {
    margin: 0 0 13px;
    font-size: 15px;
    font-weight: 700;
    color: ${p => p.theme.colors.primary.blue};
  }

  margin-bottom: 20px;
  ${minWidth(BreakPoint.TabletLandscape)} {
    margin-bottom: 40px;
  }
`;

export const Rules = styled.div`
  display: grid;
  flex-direction: column;
  gap: 8px;

  ${minWidth(BreakPoint.TabletLandscape)} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Rule = styled.div<{ $valid?: boolean; }>`
  display: flex;
  align-items: center;
  gap: 4px;

  color: ${p => p.theme.colors.blues.b50};
  font-size: 12px;
  font-weight: 500;


  svg {
    width: 12px;
    height: 12px;

    ${p => p.$valid && css`
      color: #34C69A;
    `};
  }
`;
