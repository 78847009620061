import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { Select } from '~/components';
import { ChevronBottomIcon } from '~/icons';
import { getErrorMessage } from '~/utils';

import { Container, Helper } from '../Field/Field.styles';

type Props = ComponentPropsWithoutRef<'select'> & { label?: string; };

export const FieldSelect = forwardRef(({ className, label, ...selectProps }: Props, ref: ForwardedRef<HTMLSelectElement>) => {
  const { formState: { errors } } = useFormContext();

  if (!selectProps.name) return null;
  const fieldError = errors[selectProps.name];

  return (
    <Container className={className}>
      <label>{label}</label>
      <ChevronBottomIcon />
      <Select
        ref={ref}
        {...selectProps}
        $error={!!fieldError}
      />
      <Helper>{getErrorMessage(fieldError)}</Helper>
    </Container>
  );
});
