import { ComponentPropsWithRef, FC } from 'react';

import { Svg } from '~/components';

type Props = ComponentPropsWithRef<'svg'>;

export const EyeIcon: FC<Props> = ({ ...svgProps }) => {
  return (
    <Svg {...svgProps} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.50002 3.73801C11.5444 3.73801 14.3512 5.42501 15.65 8.03575L15.8334 8.40467L15.65 8.77379C14.3512 11.3843 11.5444 13.0713 8.50002 13.0713C5.45564 13.0713 2.64885 11.3843 1.35007 8.7736L1.16669 8.40467L1.35007 8.03556C2.64885 5.42501 5.45564 3.73801 8.50002 3.73801ZM5.8262 8.40437C5.8262 9.94134 7.02565 11.1919 8.49999 11.1919C9.97434 11.1919 11.1738 9.94153 11.1738 8.40437C11.1738 6.86721 9.97434 5.61666 8.49999 5.61666C7.02565 5.61666 5.8262 6.8674 5.8262 8.40437Z" fill="#54477B" />
    </Svg>
  );
};
