import { isString } from 'lodash';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

export const getErrorMessage = (fieldError?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>) => {
  if (fieldError?.message && isString(fieldError?.message)) {
    return fieldError.message;
  }
  if (fieldError?.type === 'state') {
    return 'Please select a valid state';
  }
  if (fieldError?.type === 'required' || fieldError?.type === 'requiredSelect') {
    return 'This field is required';
  }
  if (fieldError?.type === 'min1910') {
    return 'Please enter a valid year';
  }

  return null;
};
