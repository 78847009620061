import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0;

  width: 100%;

  button[type=submit] {
    align-self: center;
  }
`;
