import styled, { css } from 'styled-components';

import { Fonts } from '~/styles';

interface InputProps {
  $error?: boolean;
}

export const InputCss = css<InputProps>`
  width: 100%;

  position: relative;
  padding: 12px;
  height: 50px;
  border-radius: 12px;
  border: 1px solid ${p => p.theme.colors.blues.b20};

  font-size: 15px;
  font-weight: 500;
  font-family: ${Fonts.Satoshi};
  color: ${p => p.theme.colors.primary.blue};

  &::placeholder {
    color: ${p => p.theme.colors.blues.b50};
  }

  &:focus {
    ${p => !p.$error && css`
    border-color: ${p.theme.colors.primary.blue};
    outline: 1px solid ${p.theme.colors.primary.blue};
    `};
  }

  ${p => p.$error && css`
    color: ${p.theme.colors.semantic.error};
    border-color: ${p.theme.colors.semantic.error};
    outline: 1px solid ${p.theme.colors.semantic.error};
  `};
`;

export const InputStyled = styled.input`
  ${InputCss};
`;
