import { FC } from 'react';

export const DreemMail: FC = () => {
  return (
    <svg width="111" height="75" viewBox="0 0 111 75" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.64014 24.4334L10.8603 24.0507" stroke="#FFE188" strokeWidth="2.42807" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M22.4888 4.32946L22.8685 10.5214" stroke="#FFE188" strokeWidth="2.42807" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M36.2798 9.03584L32.1497 13.6946" stroke="#FFE188" strokeWidth="2.42807" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M9.39258 10.7073L14.0552 14.8091" stroke="#FFE188" strokeWidth="2.42807" strokeMiterlimit="10" strokeLinecap="round" />
      <mask id="mask0_5889_43876" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="23" y="22" width="78" height="46">
        <rect x="23.3892" y="22.3638" width="77.1832" height="45.2017" rx="2.42807" fill="url(#paint0_linear_5889_43876)" />
      </mask>
      <g mask="url(#mask0_5889_43876)">
        <rect x="23.3892" y="22.3638" width="77.1832" height="45.2017" rx="2.42807" fill="#FFD5DA" />
        <path d="M54.6156 44.0841L22.2104 67.859" stroke="#FF8795" strokeWidth="0.485614" />
        <path d="M69.6404 44.0841L101.751 67.859" stroke="#FF8795" strokeWidth="0.485614" />
        <g filter="url(#filter0_d_5889_43876)">
          <path d="M61.0302 48.2469L23.3892 27.1232V21.1898H100.572V27.1232L62.9314 48.2469C62.341 48.5783 61.6206 48.5783 61.0302 48.2469Z" fill="#FF8795" />
        </g>
      </g>
      <ellipse cx="97.46" cy="61.7342" rx="8.86869" ry="8.83633" fill="#3D2D6E" />
      <path fillRule="evenodd" clipRule="evenodd" d="M97.46 69.6743C101.861 69.6743 105.429 66.1194 105.429 61.7343C105.429 57.3491 101.861 53.7942 97.46 53.7942C93.0587 53.7942 89.4908 57.3491 89.4908 61.7343C89.4908 66.1194 93.0587 69.6743 97.46 69.6743ZM97.46 71.0755C102.638 71.0755 106.835 66.8933 106.835 61.7343C106.835 56.5752 102.638 52.393 97.46 52.393C92.282 52.393 88.0845 56.5752 88.0845 61.7343C88.0845 66.8933 92.282 71.0755 97.46 71.0755Z" fill="#3D2D6E" />
      <path fillRule="evenodd" clipRule="evenodd" d="M101.516 58.5615C101.814 58.8092 101.855 59.251 101.606 59.5482L96.7982 65.2967C96.6717 65.4479 96.4872 65.5391 96.2899 65.5481C96.0925 65.557 95.9005 65.4828 95.7608 65.3436L93.3568 62.9484C93.0822 62.6748 93.0822 62.2312 93.3568 61.9576C93.6314 61.684 94.0766 61.684 94.3512 61.9576L96.2108 63.8103L100.526 58.6512C100.774 58.354 101.218 58.3138 101.516 58.5615Z" fill="white" />
      <defs>
        <filter id="filter0_d_5889_43876" x="21.4467" y="21.1898" width="81.068" height="31.1906" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1.94246" />
          <feGaussianBlur stdDeviation="0.971228" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.239216 0 0 0 0 0.211765 0 0 0 0 0.431373 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5889_43876" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5889_43876" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_5889_43876" x1="61.7766" y1="67.5655" x2="61.7919" y2="22.3638" gradientUnits="userSpaceOnUse">
          <stop stopColor="#A69DFF" />
          <stop offset="1" stopColor="#D1D7FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
