import { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { RegisterUserVariables, registerUser } from '~/api';
import { Button, Field, FieldSelect, Fields, Form, NonFieldErrors } from '~/components';
import { H2Container } from '~/components/Fields/Fields.styles';
import { useFormErrors, usePasswordIsValid, useQueryParams } from '~/hooks';
import { CheckIcon } from '~/icons';

import { Container, PasswordHelper, Rule, Rules } from './RegistrationPage.styles';

type FormData = RegisterUserVariables;

export const RegistrationPage: FC = () => {
  const params = useQueryParams();

  const methods = useForm<FormData>({
    defaultValues: !!params.get('prefill') ? {
      email: 'ppp@pierreyves.paris',
      first_name: 'Pierre-Yves',
      last_name: 'Hatton',
      password1: 'password1234!!!',
      password2: 'password1234!!!',
      // sex: 1,
      // date_of_birth: '1990-01-01',
      // phone_number: '+10606060606',
      // address_first_line: '1 rue de la paix',
      // city: 'Paris',
      // zip_code: '75000',
      // administrative_area: 'Ile de France',
      // country: 'FR',
    } : {},
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });
  const { register, setError, formState: { isSubmitted } } = methods;

  const { data: result, mutate, isLoading, isSuccess } = useMutation({
    mutationFn: registerUser,
  });

  const formSubmittedWithSuccess = useMemo(() => {
    return isSuccess && isSubmitted && 'success' in result;
  }, [isSuccess, isSubmitted, result]);

  const { nonFieldErrors } = useFormErrors(result, setError);

  const [passwordToCheck, setPasswordtoCheck] = useState('');
  const {
    has8characters,
    hasLowercase,
    hasUppercase,
    hasNumber,
    hasSpecialCharacter,
  } = usePasswordIsValid(passwordToCheck);

  useEffect(() => {
    if (formSubmittedWithSuccess) {
      window.location.href = '/account-created';
    }
  }, [formSubmittedWithSuccess]);

  return (
    <Container>
      <h1>Registration</h1>

      <p>
        Create an Dreem Health account. <br />
        *indicates a required field.
      </p>

      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(submitForm)}>

          {nonFieldErrors.length > 0 && (
            <NonFieldErrors messages={nonFieldErrors} />
          )}

          <Fields>
            <H2Container>
              <h2>Personnal information</h2>
            </H2Container>

            <Field
              isRequired
              label="First name"
              placeholder="First name"
              {...register('first_name', {
                required: true,
              })}
            />

            <Field
              isRequired
              label="Last name"
              placeholder="Last name"
              {...register('last_name', {
                required: true,
              })}
            />

            <FieldSelect
              label="Gender"
              {...register('sex', {
                required: false,
                // validate: {
                //   requiredSelect(value) {
                //     return value === 0 || value === 1 || value === 2;
                //   },
                // },
              })}
            >
              <option value="">Gender</option>
              <option value={0}>Female</option>
              <option value={1}>Male</option>
              <option value={2}>Other</option>
            </FieldSelect>

            <Field
              type="date"
              label="Date of birth"
              placeholder="Date of birth"
              {...register('date_of_birth', {
                required: false,
              })}
            />

            <Field
              isRequired
              label="Email"
              type="email"
              placeholder="Email"
              {...register('email', {
                required: true,
                pattern: {
                  value: /^[a-zA-Z0-9._-]{1,63}@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: 'Entered value does not match email format',
                },
              })}
            />

            <Field
              label="Phone number"
              placeholder="Phone number"
              {...register('phone_number', {
                required: false,
              })}
            />

          </Fields>

          <Fields>
            <H2Container>
              <h2>Password</h2>
            </H2Container>
            <Field
              isRequired
              label="Password"
              type="password"
              placeholder="Password"
              {...register('password1', {
                required: true,
                onChange: (e) => setPasswordtoCheck(e.target.value),
              })}
            />

            <Field
              isRequired
              label="Password confirmation"
              type="password"
              placeholder="Confirm password"
              {...register('password2', {
                required: true,
              })}
            />
          </Fields>

          <PasswordHelper>
            <h3>Your password needs to have:</h3>
            <Rules>
              <Rule $valid={hasLowercase}>
                <CheckIcon />
                At least one lowercase letter
              </Rule>
              <Rule $valid={hasUppercase}>
                <CheckIcon />
                At least one uppercase letter
              </Rule>
              <Rule $valid={hasNumber}>
                <CheckIcon />
                At least one number
              </Rule>
              <Rule $valid={hasSpecialCharacter}>
                <CheckIcon />
                At least one special character
              </Rule>
              <Rule $valid={has8characters}>
                <CheckIcon />
                At least 8 characters
              </Rule>
            </Rules>
          </PasswordHelper>

          <Fields cols={3}>
            <H2Container>
              <h2>Contact</h2>
            </H2Container>
            <Field
              label="Street address"
              className="full"
              placeholder="Street address"
              {...register('address_first_line', {
                required: false,
              })}
            />

            <Field
              label="City"
              placeholder="City"
              {...register('city', {
                required: false,
              })}
            />

            <Field
              label="Zip code"
              placeholder="Zip code"
              {...register('zip_code', {
                required: false,
              })}
            />

            <Field
              label="State"
              placeholder="State"
              {...register('administrative_area', {
                required: false,
              })}
            />

            <input type="hidden" {...register('country')} />
          </Fields>

          <Button type="submit" loading={isLoading || formSubmittedWithSuccess}>
            Create account
          </Button>
        </Form>
      </FormProvider>
    </Container>
  );

  function submitForm(formData: FormData) {
    console.warn('SUBMIT_FORM :::', formData);
    mutate(formData);
  }
};
