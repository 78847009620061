import { FC } from 'react';

import { Container } from './NonFieldErrors.styles';

interface Props {
  messages: Array<string>;
}

export const NonFieldErrors: FC<Props> = ({ messages }) => {
  return (
    <Container>
      <h2>
        {messages.length > 1 ? 'There were some errors:' : 'There was an error:'}
      </h2>
      {messages.length > 1 ? (
        <ul>
          {messages.map(message => (
            <li key={message}>{message}</li>
          ))}
        </ul>
      ) : (
        <>
          {messages[0]}
        </>
      )}

    </Container>
  );
};
