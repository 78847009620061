import { FC } from 'react';

export const DreemStars: FC = () => {
  return (
    <svg width="148" height="100" viewBox="0 0 148 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="61.8506" cy="88.4172" rx="2.20896" ry="2.20031" fill="#FF8795" />
      <path d="M122.79 4.80527L122.907 9.0677" stroke="url(#paint0_linear_4678_173453)" strokeWidth="2.08451" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M132.021 8.46291L129.119 11.5332" stroke="url(#paint1_linear_4678_173453)" strokeWidth="2.08451" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M113.774 8.77643L116.837 11.7399" stroke="url(#paint2_linear_4678_173453)" strokeWidth="2.08451" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M122.665 28.3626L122.777 24.1001" stroke="url(#paint3_linear_4678_173453)" strokeWidth="2.08451" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M131.892 24.6948L128.986 21.6277" stroke="url(#paint4_linear_4678_173453)" strokeWidth="2.08451" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M131.892 24.6948L128.986 21.6277" stroke="url(#paint5_linear_4678_173453)" strokeWidth="2.08451" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M113.644 24.4014L116.703 21.4345" stroke="url(#paint6_linear_4678_173453)" strokeWidth="2.08451" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M114.683 16.739L110.447 16.7497" stroke="url(#paint7_linear_4678_173453)" strokeWidth="2.08451" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M136.011 16.739L131.775 16.7497" stroke="url(#paint8_linear_4678_173453)" strokeWidth="2.08451" strokeMiterlimit="10" strokeLinecap="round" />
      <ellipse cx="50.2534" cy="10.8562" rx="1.65672" ry="1.65023" fill="#FF8795" />
      <ellipse cx="26.5078" cy="59.8131" rx="2.20896" ry="2.20031" fill="white" />
      <ellipse cx="117.074" cy="47.7114" rx="2.20896" ry="2.20031" fill="url(#paint9_linear_4678_173453)" />
      <ellipse cx="20.9843" cy="73.0149" rx="2.20896" ry="2.20031" fill="url(#paint10_linear_4678_173453)" />
      <g filter="url(#filter0_f_4678_173453)">
        <ellipse cx="130.425" cy="79.7108" rx="2.85641" ry="2.84523" fill="white" />
      </g>
      <path d="M130.329 68.6144L130.873 77.1263C130.94 78.1733 131.774 79.0074 132.821 79.0738L141.374 79.6159L132.821 80.1581C131.774 80.2245 130.94 81.0586 130.873 82.1055L130.329 90.6175L129.785 82.1055C129.718 81.0586 128.884 80.2245 127.837 80.1581L119.284 79.6159L127.837 79.0738C128.884 79.0074 129.718 78.1733 129.785 77.1263L130.329 68.6144Z" fill="url(#paint11_linear_4678_173453)" />
      <g filter="url(#filter1_f_4678_173453)">
        <ellipse cx="21.0755" cy="22.827" rx="3.67905" ry="3.66466" fill="white" />
      </g>
      <path d="M21.0994 10.3061L21.5915 20.0543C21.6454 21.1233 22.5001 21.9776 23.5691 22.0312L33.363 22.5216L23.5691 23.0121C22.5001 23.0656 21.6454 23.92 21.5915 24.9889L21.0994 34.7372L20.6074 24.9889C20.5535 23.92 19.6988 23.0656 18.6298 23.0121L8.83594 22.5216L18.6298 22.0312C19.6988 21.9776 20.5535 21.1233 20.6074 20.0543L21.0994 10.3061Z" fill="url(#paint12_linear_4678_173453)" />
      <ellipse cx="73.9997" cy="49.9117" rx="25.403" ry="25.3036" fill="#FF8795" />
      <path fillRule="evenodd" clipRule="evenodd" d="M74.0006 74.2251C87.4813 74.2251 98.4095 63.3396 98.4095 49.9117C98.4095 36.4837 87.4813 25.5982 74.0006 25.5982C60.5199 25.5982 49.5916 36.4837 49.5916 49.9117C49.5916 63.3396 60.5199 74.2251 74.0006 74.2251ZM74.0006 78.5158C89.8602 78.5158 102.717 65.7093 102.717 49.9117C102.717 34.1141 89.8602 21.3076 74.0006 21.3076C58.141 21.3076 45.2842 34.1141 45.2842 49.9117C45.2842 65.7093 58.141 78.5158 74.0006 78.5158Z" fill="#FF8795" />
      <path fillRule="evenodd" clipRule="evenodd" d="M86.4238 40.1958C87.3376 40.9543 87.4611 42.3071 86.6996 43.2173L71.9732 60.8198C71.5858 61.2828 71.0208 61.5622 70.4163 61.5895C69.8118 61.6168 69.2236 61.3896 68.7958 60.9634L61.4326 53.629C60.5915 52.7912 60.5915 51.4329 61.4326 50.5951C62.2737 49.7573 63.6373 49.7573 64.4784 50.5951L70.1739 56.2683L83.3905 40.4705C84.152 39.5603 85.51 39.4373 86.4238 40.1958Z" fill="white" />
      <defs>
        <filter id="filter0_f_4678_173453" x="121.315" y="70.6121" width="18.2199" height="18.1975" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="3.12676" result="effect1_foregroundBlur_4678_173453" />
        </filter>
        <filter id="filter1_f_4678_173453" x="12.1852" y="13.9511" width="17.7809" height="17.7519" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.60563" result="effect1_foregroundBlur_4678_173453" />
        </filter>
        <linearGradient id="paint0_linear_4678_173453" x1="121.583" y1="5.21625" x2="123.403" y2="4.87388" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE188" />
          <stop offset="1" stopColor="#FFF3E6" />
        </linearGradient>
        <linearGradient id="paint1_linear_4678_173453" x1="128.478" y1="9.66876" x2="132.275" y2="11.2545" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE188" />
          <stop offset="1" stopColor="#FFF3E6" />
        </linearGradient>
        <linearGradient id="paint2_linear_4678_173453" x1="113.774" y1="8.77643" x2="116.521" y2="8.50784" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE188" />
          <stop offset="1" stopColor="#FFF3E6" />
        </linearGradient>
        <linearGradient id="paint3_linear_4678_173453" x1="121.457" y1="27.9529" x2="123.278" y2="28.2933" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE188" />
          <stop offset="1" stopColor="#FFF3E6" />
        </linearGradient>
        <linearGradient id="paint4_linear_4678_173453" x1="128.347" y1="23.4929" x2="132.143" y2="21.903" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE188" />
          <stop offset="1" stopColor="#FFF3E6" />
        </linearGradient>
        <linearGradient id="paint5_linear_4678_173453" x1="128.347" y1="23.4929" x2="132.143" y2="21.903" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE188" />
          <stop offset="1" stopColor="#FFF3E6" />
        </linearGradient>
        <linearGradient id="paint6_linear_4678_173453" x1="113.644" y1="24.4014" x2="116.39" y2="24.6669" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE188" />
          <stop offset="1" stopColor="#FFF3E6" />
        </linearGradient>
        <linearGradient id="paint7_linear_4678_173453" x1="111.373" y1="18.4883" x2="112.806" y2="14.6457" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE188" />
          <stop offset="1" stopColor="#FFF3E6" />
        </linearGradient>
        <linearGradient id="paint8_linear_4678_173453" x1="132.701" y1="18.4883" x2="134.134" y2="14.6457" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE188" />
          <stop offset="1" stopColor="#FFF3E6" />
        </linearGradient>
        <linearGradient id="paint9_linear_4678_173453" x1="114.865" y1="45.511" x2="120.178" y2="48.002" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE188" />
          <stop offset="1" stopColor="#FFF3E6" />
        </linearGradient>
        <linearGradient id="paint10_linear_4678_173453" x1="18.7754" y1="70.8146" x2="24.0886" y2="73.3056" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE188" />
          <stop offset="1" stopColor="#FFF3E6" />
        </linearGradient>
        <linearGradient id="paint11_linear_4678_173453" x1="119.284" y1="68.6144" x2="145.85" y2="81.0692" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE188" />
          <stop offset="1" stopColor="#FFF3E6" />
        </linearGradient>
        <linearGradient id="paint12_linear_4678_173453" x1="8.83594" y1="10.3061" x2="38.3334" y2="24.1352" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE188" />
          <stop offset="1" stopColor="#FFF3E6" />
        </linearGradient>
      </defs>
    </svg>
  );
};
