import { createGlobalStyle } from 'styled-components';

import { BreakPoint, minWidth } from './responsive';
import { Fonts } from './theme';

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body {
    width: 100%;
    height: 100%;
    font-family: ${Fonts.Satoshi};
  }

  #root {
    width: 100%;
    height: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased;
    padding: 0;
    margin: 0;
    background-color: #fbf8f6;
    color: ${p => p.theme.colors.primary.blue};

    padding: 32px;
  }

  h1 {
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    text-align: center;

    span {
      color: ${p => p.theme.colors.primary.pink};
    }

    ${minWidth(BreakPoint.TabletLandscape)} {
      font-size: 32px;
      line-height: 40px;
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    ${minWidth(BreakPoint.TabletLandscape)} {
      font-size: 19px;
      line-height: 29px;
    }
  }

  button {
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
  }

  a {
    text-decoration: underline;
    color: inherit;
    &:hover {
      color: ${p => p.theme.colors.primary.blue};
    }
    &:active {
      color: ${p => p.theme.colors.primary.blue};
    }
  }
`;
