import { toFormData } from '~/utils';

export type ResetPasswordVariables = {
  email: string;
};

type ResetPasswordResult = {
  success: {
    detail: string;
  };
};

export type ResetPasswordVariableField = keyof ResetPasswordVariables;

type RegisterUserErrors = {
  errors: Partial<{
    [K in ResetPasswordVariableField]: Array<string>;
  }>;
};

export type ResetPasswordMutationRes = ResetPasswordResult | RegisterUserErrors;

export const resetPassword = async (variables: ResetPasswordVariables): Promise<ResetPasswordMutationRes> => {
  const fetchResetPassword = await fetch(process.env.API_URL + '/auth/password/reset/', {
    method: 'POST',
    body: toFormData({
      ...variables,
    }),
  });

  const res = await fetchResetPassword.json();

  if (![200, 201].includes(fetchResetPassword.status)) {
    return {
      errors: res,
    };
  }

  return {
    success: res,
  };
};
