import { FC } from 'react';

import { Svg } from '~/components';

export const ChevronBottomIcon: FC = () => {
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.74408 6.07725C3.06952 5.75181 3.59715 5.75181 3.92259 6.07725L10 12.1547L16.0774 6.07725C16.4029 5.75181 16.9305 5.75181 17.2559 6.07725C17.5814 6.40269 17.5814 6.93032 17.2559 7.25576L10.5893 13.9224C10.433 14.0787 10.221 14.1665 10 14.1665C9.77899 14.1665 9.56703 14.0787 9.41074 13.9224L2.74408 7.25576C2.41864 6.93032 2.41864 6.40268 2.74408 6.07725Z" fill="#54477B" />
    </Svg>
  );
};
