import { FC, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { SetNewPasswordMutationVariables, setNewPassword } from '~/api';
import { Button, Field, Fields, Form, NonFieldErrors } from '~/components';
import { useFormErrors, usePasswordIsValid } from '~/hooks';
import { CheckIcon } from '~/icons';
import { DreemSecure } from '~/svg';

import { Container, Rule, Rules } from './ResetPasswordConfirmationPage.styles';

type FormData = SetNewPasswordMutationVariables;

export const ResetPasswordConfirmationPage: FC = () => {
  const p = useParams();
  const [newPasswordToCheck, setNewPasswordToCheck] = useState('');

  const {
    hasLowercase,
    hasUppercase,
    hasNumber,
    hasSpecialCharacter,
    has8characters,
    hasAllConditions,
  } = usePasswordIsValid(newPasswordToCheck);

  const methods = useForm<FormData>({
    defaultValues: {
      new_password1: '',
      new_password2: '',
      uid: p.uid,
      token: p.token,
    },
  });

  const { register,
    handleSubmit,
    formState: { isSubmitted, isValid },
    setError,
  } = methods;

  const {
    data: result,
    mutate,
    isLoading,
    isSuccess,
  } = useMutation({ mutationFn: setNewPassword });
  const { nonFieldErrors } = useFormErrors(result, setError);

  const formSubmittedWithSuccess = useMemo(() => {
    return isSuccess && isSubmitted && 'success' in result;
  }, [isSuccess, isSubmitted, result]);

  if (formSubmittedWithSuccess) {
    return (
      <Container>
        <DreemSecure />
        <h1>
          Your password is <br />
          <span>successfully set!</span>
        </h1>
      </Container>
    );
  }

  return (
    <Container>
      <h1>Set new password</h1>
      <p>
        Make sure to meet the criteria
      </p>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {nonFieldErrors.length > 0 && (
            <NonFieldErrors messages={nonFieldErrors} />
          )}
          <Fields cols={1}>
            <Field
              type="password"
              label="New password"
              placeholder="New password"
              {...register('new_password1', {
                required: true,
                onChange: (e) => setNewPasswordToCheck(e.target.value),
              })}
            />
            <Field
              type="password"
              label="Confirm your new password"
              placeholder="Confirm your new password"
              {...register('new_password2', {
                required: true,
              })}
            />
            <input type="hidden" {...register('uid', { required: true })} />
            <input type="hidden" {...register('token', { required: true })} />
          </Fields>

          <Rules>
            <Rule $valid={hasLowercase}>
              <CheckIcon />
              At least one lowercase letter
            </Rule>
            <Rule $valid={hasUppercase}>
              <CheckIcon />
              At least one uppercase letter
            </Rule>
            <Rule $valid={hasNumber}>
              <CheckIcon />
              At least one number
            </Rule>
            <Rule $valid={hasSpecialCharacter}>
              <CheckIcon />
              At least one special character
            </Rule>
            <Rule $valid={has8characters}>
              <CheckIcon />
              At least 8 characters
            </Rule>
          </Rules>

          <Button type="submit" loading={isLoading} disabled={!hasAllConditions || !isValid}>
            Set new password
          </Button>
        </Form>
      </FormProvider>
    </Container>
  );

  function onSubmit(formData: FormData) {
    mutate(formData);
  }
};
