export const defaultTheme = {
  colors: {
    primary: {
      blue: '#2A195A',
      pink: '#FF8795',
      white: '#FFFFFF',
    },
    secondary: {
      yellow: '#FFE188',
      eggshell: '#F3ECE7',
      sand: '#FFF3E6',
    },
    blues: {
      b10: '#EAE8EF',
      b20: '#DDDAE5',
      b35: '#B4AEC5',
      b40: '#978FAE',
      b50: '#746994',
      b70: '#54477B',
    },
    pinks: {
      p20: '#FFE7EA',
    },
    semantic: {
      error: '#CC4858',
    },
    transparent: {
      t06: 'rgba(42, 26, 91, 0.6)',
    },
    mark: '#FFEFBF',
  },
};

export enum Fonts {
  Fractul = 'Fractul',
  Satoshi = 'Satoshi',
  Galano = 'Galano',
}

export type Theme = typeof defaultTheme;
