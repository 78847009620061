import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 12px 0 32px;
  }

  button[type=submit] {
    margin-top: 32px;
  }

  svg.eye-icon {
    cursor: pointer;
  }
`;

export const Rules = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Rule = styled.div<{ $valid?: boolean; }>`
  display: flex;
  align-items: center;
  gap: 4px;

  color: ${p => p.theme.colors.blues.b50};
  font-size: 12px;
  font-weight: 500;
  
  svg {
    width: 12px;
    height: 12px;

    ${p => p.$valid && css`
      color: #34C69A;
    `};
  }
`;
