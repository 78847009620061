import { FC } from 'react';

export const DreemSecure: FC = () => {
  return (
    <svg width="148" height="127" viewBox="0 0 148 127" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="40.4697" y="65.3143" width="73.509" height="47.5646" rx="6.2666" fill="#3D2D6E" />
      <path d="M59.2039 79.9209H68.2136V36.3429C68.2136 29.6651 73.6811 24.2293 80.3978 24.2293C87.1146 24.2293 92.5821 29.6651 92.5821 36.3429V79.9155H101.592V36.3429C101.592 24.7239 92.0845 15.2717 80.3978 15.2717C68.7111 15.2717 59.2039 24.7239 59.2039 36.3429V79.9209Z" fill="#CEE4FF" />
      <path d="M50.9187 61.5384H109.871C112.932 61.5384 115.409 64.0063 115.409 67.0441V102.304C115.409 105.348 112.927 107.81 109.871 107.81H50.9187C47.8578 107.81 45.3809 105.342 45.3809 102.304V67.0441C45.3809 64.0063 47.8632 61.5384 50.9187 61.5384ZM77.6613 81.6041C77.6613 83.1042 78.8835 84.3193 80.3924 84.3193C81.9012 84.3193 83.1234 83.1042 83.1234 81.6041V76.1199C83.1234 74.6198 81.9012 73.4047 80.3924 73.4047C78.8835 73.4047 77.6613 74.6198 77.6613 76.1199V81.6041Z" fill="#CEE4FF" />
      <circle cx="115.135" cy="102.751" r="15.6524" fill="#FF8795" />
      <path fillRule="evenodd" clipRule="evenodd" d="M122.293 97.13C122.82 97.5688 122.891 98.3513 122.452 98.8778L113.966 109.061C113.743 109.328 113.418 109.49 113.069 109.506C112.721 109.522 112.382 109.39 112.136 109.144L107.893 104.901C107.408 104.416 107.408 103.63 107.893 103.146C108.377 102.661 109.163 102.661 109.648 103.146L112.93 106.428L120.545 97.2889C120.984 96.7623 121.767 96.6912 122.293 97.13Z" fill="white" />
      <path d="M133.105 13.7592L134.229 19.5337C134.418 20.505 135.203 21.2489 136.183 21.3855L143.288 22.3758L136.183 23.366C135.203 23.5026 134.418 24.2466 134.229 25.2178L133.105 30.9924L131.981 25.2178C131.792 24.2466 131.007 23.5026 130.027 23.366L122.922 22.3758L130.027 21.3855C131.007 21.2489 131.792 20.505 131.981 19.5337L133.105 13.7592Z" fill="#CEE4FF" />
      <path d="M143.274 52.7307C140.664 52.1361 138.064 53.78 137.466 56.4026C136.869 59.0252 138.5 61.6333 141.11 62.2279C143.72 62.8226 146.32 61.1786 146.918 58.556C147.515 55.9334 145.884 53.3253 143.274 52.7307Z" fill="#FFE188" />
      <path d="M9.39989 57.1433L10.3921 63.1672C10.5936 64.3906 11.5525 65.3495 12.7759 65.551L18.7998 66.5432L12.7759 67.5354C11.5525 67.7369 10.5936 68.6958 10.3921 69.9192L9.39989 75.9431L8.40766 69.9192C8.20615 68.6958 7.24729 67.7369 6.02391 67.5354L0 66.5432L6.02391 65.551C7.24729 65.3495 8.20615 64.3906 8.40766 63.1672L9.39989 57.1433Z" fill="#FF8795" />
      <path d="M33.2999 16.0499C31.6416 15.6721 29.9896 16.7166 29.61 18.3829C29.2303 20.0492 30.2669 21.7063 31.9252 22.0841C33.5834 22.4619 35.2355 21.4174 35.6151 19.7511C35.9948 18.0848 34.9582 16.4277 33.2999 16.0499Z" fill="#FFE188" />
    </svg>
  );
};
