import { FC } from 'react';

import { DreemExpired } from '~/svg';

import { Container } from './LinkExpiredPage.styles';

export const LinkExpiredPage: FC = () => {
  return (
    <Container>
      <DreemExpired />
      <h1>
        Your verification link<br />
        <span>has expired</span>
      </h1>
      <p>
        Your link has run its course. Please go back to your app and click “resend” for a new verification link.
      </p>
    </Container>
  );
};
