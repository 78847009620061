import { LoaderFunction } from 'react-router-dom';

export const activateLoader: LoaderFunction = async () => {
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');

  const { status } = await fetch(process.env.API_URL + '/api/sleep-clinic/password-reset/activate/?token=' + token, {
    method: 'GET',
  });

  return status === 200;
};
