import styled from 'styled-components';

import { BreakPoint, minWidth } from '~/styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    margin: 12px 0 32px;
    text-align: center;

    br {
      display: none;
    }

    ${minWidth(BreakPoint.TabletLandscape)} {
      br {
        display: block;
      }
    }
  }
`;
