import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { DreemLogo } from '~/svg';

import { Container, Content, Header, Wrapper } from './MainLayout.styles';

interface Props {
  largeWrapper?: boolean;
}

export const MainLayout: FC<Props> = ({ largeWrapper }) => {
  return (
    <Container $large={largeWrapper}>
      <Wrapper>
        <Header>
          <DreemLogo />
        </Header>
        <Content>
          <Outlet />
        </Content>
      </Wrapper>
    </Container>
  );
};
