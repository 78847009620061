import { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { ResetPasswordVariables, resetPassword } from '~/api';
import { Button, Field, Form, NonFieldErrors } from '~/components';
import { useFormErrors } from '~/hooks';

import { Container } from './ResetPasswordPage.styles';

type FormData = ResetPasswordVariables;

export const ResetPasswordPage: FC = () => {
  const methods = useForm<FormData>({
    defaultValues: {
      email: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });
  const { register, setError, formState: { isSubmitted } } = methods;

  const { data: result, mutate, isLoading, isSuccess } = useMutation({
    mutationFn: resetPassword,
  });

  const { nonFieldErrors } = useFormErrors(result, setError);

  const formSubmittedWithSuccess = useMemo(() => {
    return isSuccess && isSubmitted && 'success' in result;
  }, [isSuccess, isSubmitted, result]);

  useEffect(() => {
    if (formSubmittedWithSuccess) {
      window.location.href = '/you-have-got-mail';
    }
  }, [formSubmittedWithSuccess]);

  return (
    <Container>
      <h1>Reset password</h1>

      <p>
        Memory slip-up? It happens to the best of us! <br />
        Enter your email address to reset your password
      </p>

      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(submitForm)}>

          {nonFieldErrors.length > 0 && (
            <NonFieldErrors messages={nonFieldErrors} />
          )}

          <Field
            label="Email"
            placeholder="louisa@gmail.com"
            {...register('email', {
              required: true,
              pattern: {
                value: /^[a-zA-Z0-9._+-]{1,63}@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: 'Entered value does not match email format',
              },
            })}
          />

          <Button type="submit" loading={isLoading || formSubmittedWithSuccess}>
            Reset password
          </Button>
        </Form>
      </FormProvider>
    </Container>
  );

  function submitForm(formData: FormData) {
    console.warn('SUBMIT_FORM :::', formData);
    mutate(formData);
  }
};
