import { FC } from 'react';

export const DreemExpired: FC = () => {
  return (
    <svg width="110" height="132" viewBox="0 0 110 132" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M79.6113 32.4732L35.6113 32.4732L36.6 47.7982C37.2379 57.6848 42.419 66.7184 50.6305 72.2612L53.1368 73.9529C54.3366 74.7627 54.3053 76.5394 53.0779 77.3066L51.5627 78.2535C42.8065 83.7262 37.2292 93.0877 36.5851 103.393L35.6113 118.973L79.6113 118.973L78.6376 103.393C77.9935 93.0877 72.4161 83.7262 63.6599 78.2535L62.1448 77.3066C60.9173 76.5394 60.8861 74.7627 62.0858 73.9529L64.5921 72.2612C72.8036 66.7184 77.9848 57.6848 78.6226 47.7982L79.6113 32.4732Z" fill="url(#paint0_linear_5889_42779)" />
      <path d="M60.1162 74.9683C59.6162 76.9683 58.7434 87.9683 58.1162 99.9683C57.3726 96.2001 57.6162 77.9683 55.6162 74.9683C53.5144 71.8156 49.7059 69.5371 47.6162 67.4683C42.7953 62.6958 41.2586 58.8974 40.6162 55.4683L46.6313 60.013L50.3208 61.2361L75.1162 56.4683C73.7127 60.6788 70.1195 66.1663 66.1162 69.4683C62.9886 72.0482 60.7211 72.549 60.1162 74.9683Z" fill="url(#paint1_linear_5889_42779)" />
      <path d="M53.4993 98.0589C49.4209 101.35 41.8705 102.468 38.6113 102.473L38.1549 113.43C38.1312 113.999 38.575 114.472 39.1443 114.472C46.0919 114.465 66.8301 114.473 75.8777 114.473C76.454 114.473 76.9045 113.988 76.8688 113.413C76.693 110.582 76.217 103.022 76.1113 102.473C74.3356 101.896 70.483 101.519 65.9914 100.454C60.5025 99.1533 60.4047 92.4856 53.4993 98.0589Z" fill="url(#paint2_linear_5889_42779)" />
      <path d="M58.6157 58.4731C48.1157 59.8454 41.846 55.9623 40.616 55.4727C46.6124 62.1355 53.9832 64.1226 59.8333 63.9771C64.8028 63.8535 69.7552 61.9046 73.5879 58.739C74.6082 57.8962 75.28 56.9624 75.116 55.9721C74.616 52.952 66.2349 57.4773 58.6157 58.4731Z" fill="url(#paint3_linear_5889_42779)" />
      <path d="M79.6113 32.4732L35.6113 32.4732L36.6 47.7982C37.2379 57.6848 42.419 66.7184 50.6305 72.2612L53.1368 73.9529C54.3366 74.7627 54.3053 76.5394 53.0779 77.3066L51.5627 78.2535C42.8065 83.7262 37.2292 93.0877 36.5851 103.393L35.6113 118.973L79.6113 118.973L78.6376 103.393C77.9935 93.0877 72.4161 83.7262 63.6599 78.2535L62.1448 77.3066C60.9173 76.5394 60.8861 74.7627 62.0858 73.9529L64.5921 72.2612C72.8036 66.7184 77.9848 57.6848 78.6226 47.7982L79.6113 32.4732Z" fill="url(#paint4_radial_5889_42779)" />
      <path d="M35.6113 118.973L79.6113 118.973L78.6226 103.648C77.9848 93.7618 72.8036 84.7282 64.5921 79.1854L62.0858 77.4937C60.8861 76.6839 60.9173 74.9072 62.1448 74.14L63.6599 73.193C72.4161 67.7204 77.9935 58.3589 78.6376 48.0532L79.6113 32.4734L35.6113 32.4734L36.5851 48.0532C37.2292 58.3589 42.8066 67.7204 51.5628 73.1931L53.0779 74.14C54.3054 74.9072 54.3366 76.6839 53.1368 77.4937L50.6305 79.1854C42.419 84.7282 37.2379 93.7618 36.6 103.648L35.6113 118.973Z" fill="url(#paint5_radial_5889_42779)" />
      <rect x="32.6113" y="116.473" width="50" height="4" rx="2" fill="#FF8795" />
      <rect x="32.6113" y="31.4734" width="50" height="4" rx="2" fill="#FF8795" />
      <path d="M7.27051 37.4359L16.793 36.8651" stroke="#FFE188" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M34.6497 6.60863L35.213 16.1195" stroke="#FFE188" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M55.7456 13.8746L49.41 21.0183" stroke="#FFE188" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M14.584 16.3677L21.7092 22.6803" stroke="#FFE188" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" />
      <g clipPath="url(#clip0_5889_42779)">
        <path d="M85.7071 91.6809C86.1101 90.9156 86.3824 90.0309 87.1207 90.107C87.6966 90.1663 88.752 91.02 89.0939 91.6016L106.498 121.208C107.466 122.854 106.341 124.957 104.469 125.001L71.0706 125.782C70.3169 125.8 68.4986 124.678 68.1573 124.098C67.8206 123.525 68.9536 123.498 69.3071 122.827L85.7071 91.6809Z" fill="#A199D7" />
        <path d="M84.1694 90.8115C84.8997 89.4247 86.8689 89.3786 87.6633 90.7298L105.459 121.002C106.233 122.318 105.307 123.98 103.782 124.015L69.625 124.815C68.0996 124.85 67.0977 123.233 67.8086 121.883L84.1694 90.8115Z" fill="#D0C5F0" />
        <path d="M86.4894 114.608C87.4288 114.586 88.1651 113.916 88.1454 113.074L87.8886 102.099C87.8695 101.284 87.1021 100.622 86.1627 100.644C85.2233 100.666 84.4563 101.364 84.4754 102.179L84.7322 113.154C84.7519 113.996 85.55 114.63 86.4894 114.608ZM88.2636 118.127L88.2509 117.584C88.2318 116.769 87.4644 116.107 86.525 116.129C85.5856 116.151 84.8186 116.848 84.8377 117.663L84.8504 118.207C84.8701 119.049 85.6682 119.683 86.6076 119.661C87.547 119.639 88.2833 118.969 88.2636 118.127Z" fill="#3D2D6E" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_5889_42779" x1="62.6113" y1="-73.5268" x2="57.4949" y2="118.973" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.8723" stopColor="#EAE4E0" />
        </linearGradient>
        <linearGradient id="paint1_linear_5889_42779" x1="60.0162" y1="88.2618" x2="59.9907" y2="60.4828" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFCED4" />
          <stop offset="1" stopColor="#FFABB5" />
        </linearGradient>
        <linearGradient id="paint2_linear_5889_42779" x1="56.4658" y1="114.392" x2="57.0171" y2="96.6082" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF8795" />
          <stop offset="1" stopColor="#FFABB5" />
        </linearGradient>
        <linearGradient id="paint3_linear_5889_42779" x1="52.2937" y1="64.9205" x2="73.4681" y2="52.8947" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF8795" />
          <stop offset="1" stopColor="#FFABB5" />
        </linearGradient>
        <radialGradient id="paint4_radial_5889_42779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(61.1113 47.4732) rotate(96.8817) scale(29.2104 36.6739)">
          <stop stopColor="white" />
          <stop offset="0.8723" stopColor="#F3ECE7" stopOpacity="0.04" />
        </radialGradient>
        <radialGradient id="paint5_radial_5889_42779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(69.1113 93.4735) rotate(139.821) scale(29.4491 36.9736)">
          <stop stopColor="white" />
          <stop offset="0.8723" stopColor="#F3ECE7" stopOpacity="0.04" />
        </radialGradient>
        <clipPath id="clip0_5889_42779">
          <rect width="45.0512" height="43.896" fill="white" transform="translate(108.48 85.6016) rotate(88.6597)" />
        </clipPath>
      </defs>
    </svg>
  );
};
